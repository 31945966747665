module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","background_color":"#fff","theme_color":"#25201d","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e2ef9ba43715b5b1e2bfec1c672f2b33"},
    },{
      plugin: require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"fontWeights":{"light":300,"regular":400,"medium":500,"bold":700},"spacing":8,"palette":{"black":"#25201d","white":"#fff","grey":"#f7f7f7","darkGrey":"#828282"},"breakpoints":{"sm":"@media (min-width: 576px)","md":"@media (min-width: 768px)","lg":"@media (min-width: 992px)","xl":"@media (min-width: 1200px)","xxl":"@media (min-width: 1400px)"},"headerHeight":100,"headerHeightSm":80}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
